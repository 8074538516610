import { useCheckout } from 'contexts/CheckoutContext';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Loyalty } from 'user/components/Loyalty/AddLoyalty/Loyalty';
import { selectUserHasLoyaltyCard } from 'user/selectors';

export const LoyaltyRegisterPage: React.FC = () => {
  const {
    loyalty: { shouldPromptForAssociationPostSignUp },
  } = useConfig();
  const hasLoyaltyCard = useSelector(selectUserHasLoyaltyCard);
  const { isFetchingBasket } = useCheckout();
  
  if (
    !shouldPromptForAssociationPostSignUp ||
    (hasLoyaltyCard && !isFetchingBasket)
  ) {
    return <Redirect to="/venues" />;
  }

  return (
    <div className="container container-md">
      <div className="panel panel-default add-loyalty">
        <Loyalty />
      </div>
    </div>
  );
};
