import { usePhrases } from 'contexts/ConfigContext';
import { LoyaltyCardImage } from 'user/components/Loyalty/SharedComponents/LoyaltyCardImage';
import { getLoyaltyLinkTitle } from 'utils';

interface LoyaltyCardHeaderProps {
  isOnUserScreen?: boolean;
}

export const LoyaltyCardHeader: React.FC<LoyaltyCardHeaderProps> = ({
  isOnUserScreen,
}) => {
  const { loyalty: loyaltyPhrases } = usePhrases();

  return (
    <>
      <LoyaltyCardImage />
      {!isOnUserScreen ? <h2>Thank you for signing up.</h2> : null}
      <h3>
        {getLoyaltyLinkTitle(
          loyaltyPhrases.addLoyaltyCard.title,
          loyaltyPhrases.cardPhrase,
        )}
      </h3>
      {loyaltyPhrases.addLoyaltyCard.description && (
        <p>
          {getLoyaltyLinkTitle(
            loyaltyPhrases.addLoyaltyCard.description,
            loyaltyPhrases.cardPhrase,
          )}
        </p>
      )}
    </>
  );
};
