import { useEffect, useState } from 'react';

import cs from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Field } from 'types/models/Forms';
import _ from 'lodash';
import { differenceInYears, isDate, isExists } from 'date-fns';
import { useConfig } from 'contexts/ConfigContext';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { isRequired } from 'core/components/RHF/validation';

interface DobProps {
  field: Field;
}

export const DateOfBirth: React.FC<DobProps> = ({ field }) => {
  const { currency } = useConfig();

  const {
    formState: { errors },
    register,
    setError,
    watch,
    clearErrors,
    setValue,
  } = useFormContext();

  const { required, label, name } = field;
  const [dayValue, setDayValue] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');
  const hasError =
    errors[name] !== undefined ||
    errors.day ||
    errors.month ||
    errors.year ||
    errors.dateOfBirth;
  const formGroupClasses = cs('form-group', hasError && 'has-error');
  const requiredLabel = required ? <span className="required">*</span> : null;
  const currentDate = new Date();
  const labelToUse = label ? label : name;

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      if (value.day !== '' && value.month !== '' && value.year !== '') {
        const dateFromInput = new Date(
          `${value.year}/${value.month}/${value.day}`,
        );

        const isValid =
          isDate(dateFromInput) &&
          isExists(
            Number(value.year),
            Number(value.month) - 1,
            Number(value.day),
          );

        if (!isValid) {
          setError('dateOfBirth', {
            type: 'custom',
            message: 'Not a valid date.',
          });
        } else if (currentDate.getFullYear() < value.year) {
          setError('dateOfBirth', {
            type: 'custom',
            message: 'Date cannot be in the future.',
          });
        } else if (value.year < 1902) {
          setError('dateOfBirth', {
            type: 'custom',
            message: 'Date must be after 1901.',
          });
          // user must be at least 18 > (current date - date of birth).getYears() >= field.min
        } else if (differenceInYears(currentDate, dateFromInput) < field.min) {
          setError('dateOfBirth', {
            type: 'custom',
            message: `Sorry, you must be at least ${field.min} years old.`,
          });
        } else {
          clearErrors('dateOfBirth');
        }
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffectSkipFirst(() => {
    setValue(
      name,
      `${yearValue}-${monthValue.padStart(2, '0')}-${dayValue.padStart(
        2,
        '0',
      )}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayValue, monthValue, yearValue]);

  useEffect(() => {
    if (field.content) {
      const [year, month, day] = field.content.split('-');
      if (day && month && year) {
        setValue('day', day);
        setDayValue(day);
        setValue('month', month);
        setMonthValue(month);
        setValue('year', year);
        setYearValue(year);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dayMonthInputs = [
    <div className="day" key={1}>
      <input
        className="form-control"
        placeholder="DD"
        type="text"
        inputMode="numeric"
        size={2}
        maxLength={2}
        {...register('day', {
          ...isRequired(labelToUse, required),
          pattern: {
            value: /\b(?:0?[1-9]|[12][0-9]|3[01])\b/,
            message: 'Invalid day.',
          },
          onBlur: (e) => (
            setDayValue(e.target.value),
            setValue(
              'day',
              e.target.value.length === 0
                ? e.target.value
                : e.target.value.padStart(2, '0'),
            )
          ),
          onChange: (e) => {
            e.target.value = e.target.value.replace(/\D+/g, '');
          },
        })}
      />
    </div>,
    <div className="month" key={2}>
      <input
        className="form-control month"
        placeholder="MM"
        type="text"
        size={2}
        inputMode="numeric"
        maxLength={2}
        {...register('month', {
          ...isRequired(labelToUse, required),
          pattern: {
            value: /\b(?:0?[1-9]|1[0-2])\b/,
            message: 'Invalid month.',
          },
          onBlur: (e) => (
            setMonthValue(e.target.value),
            setValue(
              'month',
              e.target.value.length === 0
                ? e.target.value
                : e.target.value.padStart(2, '0'),
            )
          ),
          onChange: (e) => {
            e.target.value = e.target.value.replace(/\D+/g, '');
          },
        })}
      />
    </div>,
  ];

  return (
    <div className={formGroupClasses}>
      <label className="control-label">
        {label}
        {requiredLabel}
      </label>
      <input type="hidden" {...register(name)} />
      <div className="flex dob">
        {currency === 'USD' ? dayMonthInputs.toReversed() : dayMonthInputs}
        <div className="year">
          <input
            className="form-control year"
            placeholder="YYYY"
            type="text"
            inputMode="numeric"
            size={4}
            maxLength={4}
            {...register('year', {
              ...isRequired(labelToUse, required),
              pattern: {
                value: /^\d{4}$/,
                message: 'Invalid year.',
              },
              onBlur: (e) => setYearValue(e.target.value),
              onChange: (e) => {
                e.target.value = e.target.value.replace(/\D+/g, '');
              },
            })}
          />
        </div>
      </div>
      <div className="dob-errors">
        {_.map(
          _.uniqBy(
            Object.values(
              _.pick(errors, ['dateOfBirth', 'day', 'month', 'year', name]),
            ),
            (error) => error.message,
          ),
          (error, key) => {
            return (
              error.message !== '' && (
                <span
                  key={key}
                  className="help-block"
                  role="alert"
                  data-testid="alert-block-error-birthdate"
                >
                  <p>{error.message}</p>
                </span>
              )
            );
          },
        )}
      </div>
    </div>
  );
};
