import { usePhrases } from 'contexts/ConfigContext';
import { FormGenerator } from 'core/components/RHF';
import { Field } from 'types/models/Forms';

interface LoyaltyFormBodyProps {
  loyaltyForm: Field[];
}

export const LoyaltyFormBody: React.FC<LoyaltyFormBodyProps> = ({
  loyaltyForm,
}) => {
  const { loyalty: loyaltyPhrases } = usePhrases();

  return (
    <>
      {loyaltyForm && loyaltyForm.length > 0 ? (
        <div className="col-sm-12">
          <FormGenerator definition={loyaltyForm} />
        </div>
      ) : (
        <div>Sorry, something has gone wrong. Please try again.</div>
      )}
      {loyaltyPhrases.rewardSupportingText ? (
        <p>{loyaltyPhrases.rewardSupportingText}</p>
      ) : null}
    </>
  );
};
