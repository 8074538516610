import { useEffect, useState } from 'react';

import cs from 'classnames';
import { Button } from 'react-bootstrap';
import {
  isNotOnlyWhitespace,
  isPin,
  isRequired,
  minLength,
} from 'core/components/RHF/validation';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Field } from 'types/models/Forms';

interface PinProps {
  field: Field;
}

export const Pin: React.FC<PinProps> = ({ field }) => {
  const { formState, register, setValue } = useFormContext();
  const { errors } = formState;
  const {
    min,
    required,
    label,
    placeholder,
    name,
    hasLabel = true,
    disabled,
  } = field;

  const labelToUse = label ? label : name;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const formGroupClasses = cs(
    'form-group',
    'password',
    hasError && 'has-error',
  );

  const validationRules: RegisterOptions = {
    ...isRequired(labelToUse, required),
    ...minLength(labelToUse, min ? min : 0),
    validate: {
      isPin,
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
    },
  };

  const requiredLabel = required ? <span className="required">*</span> : null;

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <div className="password-wrapper">
        {hasLabel ? (
          <label className="control-label" htmlFor={name}>
            {label}
            {requiredLabel}
          </label>
        ) : null}
        <input
          className="form-control"
          type={showPassword === true ? 'text' : 'password'}
          placeholder={placeholder ? placeholder : labelToUse}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={hasError ? 'true' : 'false'}
          {...register(name, validationRules)}
          inputMode="numeric"
          disabled={disabled}
        />
        <Button
          className="hide-show"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword === true ? 'Hide' : 'Show'}
        </Button>
      </div>

      {fieldError && (
        <span className="help-block" role="alert">
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
