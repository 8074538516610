import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Order } from 'order-history/components/Order';
import { NotFound } from 'routers';
import {
  AuthenticatedOrderingRoute,
  AuthenticatedRoute,
} from 'routers/PrivateRoute';
import { useDispatch } from 'react-redux';
import { removeNotification } from 'core/actions';
import { OrderHistoryPage } from 'order-history/OrderHistoryPage';
import { AccountDeletion, Edit } from 'user/components/LoggedInUserComponents';
import { PasswordChange } from 'user/components/PasswordChange';
import { LoyaltyRegisterPage } from 'user/components/Loyalty/AddLoyalty';
import { UserLoyaltyPage } from 'user/components/Loyalty/UserLoyalty';
import { useConfig } from 'contexts/ConfigContext';
import { UnlinkLoyaltyPage } from 'user/components/Loyalty';

export const LoggedInUserRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { env } = useConfig();

  useEffect(() => {
    dispatch(removeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <AuthenticatedRoute
        path="/user/delete-account"
        component={AccountDeletion}
      />
      <AuthenticatedOrderingRoute
        path="/user/order/:orderId"
        component={Order}
      />
      <AuthenticatedOrderingRoute
        path="/user/order"
        component={OrderHistoryPage}
      />
      <AuthenticatedOrderingRoute path="/user/edit" component={Edit} />
      <AuthenticatedOrderingRoute
        path="/user/password-change"
        component={PasswordChange}
      />
      <AuthenticatedOrderingRoute
        path="/user/loyalty/register"
        component={LoyaltyRegisterPage}
      />
      {env === 'qa1' || env === 'qa2' || env === 'development' ? (
        <AuthenticatedOrderingRoute
          path="/user/loyalty/unlink"
          component={UnlinkLoyaltyPage}
        />
      ) : null}
      <AuthenticatedOrderingRoute
        path="/user/loyalty"
        component={UserLoyaltyPage}
      />
      <Redirect exact from="/user" to="/user/order" />
      <Route component={NotFound} />
    </Switch>
  );
};
