import { apiWrapper, actionTypes } from 'xhr';

import {
  getFriendlyVenueTitle,
  addNotification,
  networkError,
} from 'core/actions';

const isErrorResponse = (response) => response?.data?.response === 'Error';

// TODO: provide site information
// TODO: ordering mode dependent
const sanitizeErrorDetail = (code, detail) => (dispatch) => {
  const restaurantTitle = dispatch(getFriendlyVenueTitle());
  const overrides = [
    {
      code: -210,
      from: 'already been paid. Please see a member of staff and quote order reference',
      to: `already been paid. Please contact the ${restaurantTitle} and quote order reference`,
    },
    {
      code: -208,
      from: 'at the moment. Please see a member of staff or try again.',
      to: 'at the moment. Please try again.',
    },
    {
      code: -218,
      from: 'It appears that your order has already been paid. Please see a member of staff and',
      to: `It appears that your order has already been paid. Please contact the ${restaurantTitle} and`,
    },
    {
      code: -505,
      from: 'your order has been paid but cannot be completed. Please see a member of staff with',
      to: `has been paid but cannot be completed. Please contact the ${restaurantTitle} and quote`,
    },
    {
      code: -209,
      from: 'has already been paid. Please see a member of staff',
      to: `has already been paid. Please contact the ${restaurantTitle} and quote order`,
    },
    {
      code: -208,
      from: 'at this moment. Please see a member of staff',
      to: `at the moment. Please contact the ${restaurantTitle}`,
    },
    {
      code: -204,
      from: '(Z204)',
      to: '',
    },
  ];

  for (let i = 0; i < overrides.length; i += 1) {
    const override = overrides[i];

    if (detail.indexOf(override.from) >= 0) {
      return detail.replace(override.from, override.to);
    }
  }

  return detail;
};

const sanitizeError = (error) => (dispatch) =>
  Promise.resolve().then(() => ({
    ...error,
    ...{
      detail: dispatch(sanitizeErrorDetail(error.code, error.detail || '')),
      code: error.code,
    },
  }));

export const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { type, ...request } = action;

    if (actionTypes.indexOf(type) > -1) {
      const state = getState();
      const url = window.env.REACT_APP_API;
      const brandToken = window.env.REACT_APP_BRAND_TOKEN;
      const userToken = state.user.get('token')
        ? state.user.get('token')
        : null;
      const networkErrorTimeout = null;
      const networkErrorName = 'Connection took too long, please try again.';
      const loyaltyCard = state.user.getIn(['user', 'loyalty_card']);

      let loyalty;

      if (loyaltyCard) {
        loyalty = {
          loyaltyCode: loyaltyCard,
          userLoyaltyCard: loyaltyCard,
          loyalty: loyaltyCard,
        };
      }

      return apiWrapper(
        request,
        brandToken,
        userToken,
        url,
        networkErrorTimeout,
        loyalty,
      ).then((response) => {
        // #272895, prevent Order & Pay specific error messages from propagating into the app.
        if (response.toString().indexOf('timeout') !== -1) {
          dispatch(networkError());
          dispatch(addNotification(networkErrorName, 'danger'));
        }

        if (isErrorResponse(response)) {
          return dispatch(sanitizeError(response.data)).then((data) => ({
            ...response,
            ...{ data },
          }));
        }

        return response;
      });
    }

    return next(action);
  };
