import { Sidebar } from 'user/components/LoggedInUserComponents';
import { useSignUp } from 'hooks/useSignUp';
import { Loader } from 'components/Loader';
import { PasswordChangeForm } from 'user/components/PasswordChange/PasswordChangeForm';

export const PasswordChange: React.FC = () => {
  const { formData, isLoadingForms } = useSignUp();

  return (
    <div className='container'>
      <Sidebar />
      <div className="col-sm-9">
        <div className="panel panel-default">
          <h2>Change Password</h2>
          {isLoadingForms || !formData ? <Loader /> : <PasswordChangeForm formData={formData}/>}
        </div>
      </div>
    </div>
  );
};
