import { useConfig } from 'contexts/ConfigContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { addLoyaltyOption } from 'types/models/Loyalty';

interface AddLoyaltyButtonsProps {
  checkedOption: addLoyaltyOption;
  handleConfirm: () => void;
  handleSkip: () => void;
}

export const AddLoyaltyButtons: React.FC<AddLoyaltyButtonsProps> = ({
  checkedOption,
  handleConfirm,
  handleSkip,
}) => {
  const { showResetLoyaltyPin } = useConfig();
  const { resendPin, isVerifying } = useLoyaltyRewards();
  const { getValues, setError } = useFormContext();

  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const hasLoyalty = checkedOption === addLoyaltyOption.hasLoyalty;

  const handleResendPin = () => {
    const cardNumber = getValues('loyalty_card');
    if (cardNumber) {
      setIsResendDisabled(true);
      resendPin(parseInt(cardNumber));
      //  Disable button for 20 seconds to stop spamming
      setTimeout(() => {
        setIsResendDisabled(false);
      }, 20000);
    } else {
      setError('loyalty_card', {
        type: 'custom',
        message: 'Card number is required to resend PIN',
      });
    }
  };

  return (
    <>
      <Button
        bsStyle="primary"
        className="btn-pe-submit btn-loyalty"
        disabled={checkedOption === addLoyaltyOption.none || isVerifying}
        type={hasLoyalty ? 'submit' : 'button'}
        onClick={hasLoyalty ? handleConfirm : handleSkip}
      >
        Continue
      </Button>
      {showResetLoyaltyPin && hasLoyalty ? (
        <Button
          bsStyle="default"
          className="btn-pe-submit btn-loyalty resend-pin"
          onClick={handleResendPin}
          disabled={isResendDisabled}
        >
          Resend PIN
        </Button>
      ) : null}
    </>
  );
};
