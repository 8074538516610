import { usePhrases } from 'contexts/ConfigContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectUserLoyaltyCardNumber } from 'user/selectors';

export const UnlinkLoyaltyPage: React.FC = () => {
  const {
    loyalty: { cardPhrase },
  } = usePhrases();

  const { unlinkLoyaltyCard } = useLoyaltyRewards();
  const loyaltyCardNumber = useSelector(selectUserLoyaltyCardNumber);

  return (
    <div className="container container-md">
      <div className="panel panel-default">
        <h2>Unlink Loyalty Card</h2>
        {loyaltyCardNumber ? (
          <>
            <p>
              Your {cardPhrase}: {loyaltyCardNumber}
            </p>
            <Button
              bsStyle="primary"
              onClick={() => unlinkLoyaltyCard(loyaltyCardNumber)}
            >
              Unlink Loyalty Card
            </Button>
          </>
        ) : (
          <p>User does not have a {cardPhrase}</p>
        )}
      </div>
    </div>
  );
};
