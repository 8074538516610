/* eslint-disable react/no-array-index-key */

import { Row } from 'react-bootstrap';
import { FieldGenerator } from 'core/components/RHF/FieldGenerator';
import { Field } from 'types/models/Forms';

interface FormGenerator {
  definition: Field[];
}

export const FormGenerator: React.FC<FormGenerator> = ({ definition }) => (
  <div className={definition.length < 3 ? '' : 'form-container'}>
    {definition.map((row, index) => (
      <Row className="form-row" key={index}>
        <FieldGenerator
          key={row.name}
          field={{
            ...row,
            width: 12,
          }}
        />
      </Row>
    ))}
  </div>
);
