import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from 'api/useAPI';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosRequestConfig } from 'axios';
import { requestBodyFormatter } from 'api/utils';
import {
  ErrorResponse,
  GetLoyaltyRewardsResponse,
  VerifyLoyaltyResponse,
  DebitLoyaltySiteRewardResponse,
  CreditLoyaltySiteRewardResponse,
  UserEnrollLoyaltyResponse,
  UserAddLoyaltyNumberResponse,
  UnlinkLoyaltyCardResponse,
} from 'types/models';
import { addNotification } from 'core/actions';
import { RedeemedRewardItem, RewardItem } from 'types/models/Loyalty';
import {
  getLoyaltyLinkTitle,
  hasOwnPropertyCaseInsensitive,
  sortByDateAscending,
} from 'utils';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { FieldValues } from 'react-hook-form';
import { useVenues } from 'contexts/VenueContext';
import { cloneDeep } from 'lodash';
import { CheckBasketParams } from 'contexts/CheckoutContext';
import {
  selectIsRegisteringFromCheckout,
  selectUserEmail,
  selectUserId,
} from 'user/selectors';
import {
  receiveUser,
  registerFromCheckout,
  updateUserData,
} from 'user/actions';
import { useHistory, useLocation } from 'react-router';
import { WLWOLocationState } from 'types/models/WLWOLocationState';

interface LoyaltyRewardContext {
  initialised: boolean;
  addLoyaltyCard: (
    formData: FieldValues,
    checkBasketCallback?: CheckBasketParams,
    isOnUserScreen?: boolean,
  ) => void;
  addLoyaltyError: string;
  creditLoyaltyReward: (
    transactionId: string,
    rewardId: string,
    rewardType: string,
    quantity?: number,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
  debitLoyaltyReward: (
    reward: RewardItem,
    quantity: number,
    closeModalCallback?: () => void,
    checkBaskeCallback?: CheckBasketParams,
  ) => void;
  enrollLoyaltyUser: (
    isOnUserScreen?: boolean,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
  isCrediting: boolean;
  getLoyaltyRewards: () => void;
  isDebiting: boolean;
  isLoyaltyRewardsLoading: boolean;
  isVerifying: boolean;
  loyaltyRewards: RewardItem[];
  loyaltyRewardsError: ErrorResponse | undefined;
  redeemedLoyaltyRewards: RedeemedRewardItem[];
  removeAllRewards: (checkBasketCallback?: CheckBasketParams) => void;
  resendPin: (loyalty_card: number) => void;
  setAddLoyaltyError: (error: string) => void;
  setRedeemedLoyaltyRewards: (rewards: RedeemedRewardItem[]) => void;
  unlinkLoyaltyCard: (cardNumber: number) => void;
  verifyUserLoyalty: (formData: FieldValues) => void;
}

export const LoyaltyRewardContext = createContext<LoyaltyRewardContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addLoyaltyCard: () => {},
  addLoyaltyError: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  creditLoyaltyReward: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  debitLoyaltyReward: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enrollLoyaltyUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getLoyaltyRewards: () => {},
  isCrediting: false,
  isDebiting: false,
  isLoyaltyRewardsLoading: false,
  isVerifying: false,
  loyaltyRewards: [],
  loyaltyRewardsError: undefined,
  redeemedLoyaltyRewards: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeAllRewards: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resendPin: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAddLoyaltyError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRedeemedLoyaltyRewards: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unlinkLoyaltyCard: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  verifyUserLoyalty: () => {},
});

export const useLoyaltyRewards = (): LoyaltyRewardContext => {
  const consumer = useContext(LoyaltyRewardContext);
  if (!consumer.initialised) {
    throw new Error('Loyalty Rewards Context Provider not initialised');
  }
  return consumer;
};

interface LoyaltyRewardContextProviderProps {
  children: React.ReactNode;
}

export const LoyaltyRewardContextProvider: React.FC<
  LoyaltyRewardContextProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const {
    url,
    creditReward,
    debitReward,
    getLoyalty,
    resendLoyaltyPin,
    userAddLoyaltyNumber,
    userEnrollLoyalty,
    userUnlinkLoyaltyCard,
    verifyLoyalty,
  } = useAPI();

  const {
    loyalty: { canAssociateLoyaltyCard },
  } = useConfig();

  const {
    loyalty: {
      cardPhrase,
      rewardPhrase,
      cardPhraseArticle,
      loyaltyProgramPhrase,
    },
  } = usePhrases();

  const { selectedVenue } = useVenues();

  const history = useHistory();
  const location = useLocation<WLWOLocationState>();
  const { from } = location.state || { from: { pathname: '/venues' } };

  const [isFetchingLoyalty, setIsFetchingLoyalty] = useState(false);
  const [isLoyaltyRewardsLoading, setIsLoyaltyRewardsLoading] = useState(false);
  const [loyaltyRewards, setLoyaltyRewards] = useState<RewardItem[]>([]);
  const [redeemedLoyaltyRewards, setRedeemedLoyaltyRewards] = useState<
    RedeemedRewardItem[]
  >([]);
  const [loyaltyRewardsError, setLoyaltyRewardsError] = useState<
    ErrorResponse | undefined
  >();
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDebiting, setIsDebiting] = useState(false);
  const [isCrediting, setIsCrediting] = useState(false);

  const [addLoyaltyError, setAddLoyaltyError] = useState('');

  const userEmail = useSelector(selectUserEmail);
  const userId = useSelector(selectUserId);
  const isRegisteringFromCheckout = useSelector(
    selectIsRegisteringFromCheckout,
  );

  const isErrorResponse = <T extends object>(response: T): boolean => {
    return (
      hasOwnPropertyCaseInsensitive(response, 'errorMessage') ||
      hasOwnPropertyCaseInsensitive(response, 'statusCode')
    );
  };

  const getLoyaltyRewards = () => {
    setIsFetchingLoyalty(true);
  };

  const handleSaveRewards = (rewards: RewardItem[]) => {
    const filteredRewards = rewards.filter(
      (reward) =>
        reward.displayReward &&
        reward.type &&
        reward.rewardId &&
        reward.costType === 'Points',
    );
    // Need to add in customer.quantity filter
    const sortedRewards = filteredRewards.sort(
      (a, b) =>
        Number(b.isUnlimited) - Number(a.isUnlimited) ||
        b.balance - a.balance ||
        sortByDateAscending(a.availableToDate, b.availableToDate) ||
        b.displayName.localeCompare(a.displayName),
    );

    setLoyaltyRewards(sortedRewards);
  };

  const fetchLoyaltyRewards = () => {
    if (!isLoyaltyRewardsLoading) {
      const getLoyaltyConfig = getLoyalty();
      setIsLoyaltyRewardsLoading(true);
      setLoyaltyRewardsError(undefined);
      const fetchLoyaltyOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers: getLoyaltyConfig.headers,
        data: requestBodyFormatter({
          method: getLoyaltyConfig.method,
          siteId: selectedVenue?.id,
          ...getLoyaltyConfig.body,
        }),
      };

      axios(fetchLoyaltyOptions)
        .then((response) => {
          const data = response.data as GetLoyaltyRewardsResponse;
          if (data.response === 'Error') {
            const err = response.data as ErrorResponse;
            setLoyaltyRewardsError(err);
          } else {
            handleSaveRewards(data.rewards);
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an unexpected error fetching Loyalty.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsLoyaltyRewardsLoading(false);
          setIsFetchingLoyalty(false);
        });
    }
  };

  const verifyUserLoyalty = (formData: FieldValues) => {
    setIsVerifying(true);

    const verifyLoyaltyConfig = verifyLoyalty();

    const verifyLoyaltyOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: verifyLoyaltyConfig.headers,
      data: requestBodyFormatter({
        method: verifyLoyaltyConfig.method,
        ...formData,
        ...verifyLoyaltyConfig.body,
      }),
    };
    axios(verifyLoyaltyOptions)
      .then((response) => {
        const data = response.data as VerifyLoyaltyResponse;
        if (data.response === 'OK') {
          // If verified get user loyalty rewards
          getLoyaltyRewards();
        } else {
          const err = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `There was a problem verifying the loyalty account. ${err.detail} (${err.code})`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            'There was an unexpected error linking the loyalty account.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const resendPin = (loyalty_card: number) => {
    const resendLoyaltyPinConfig = resendLoyaltyPin();

    const resendPinOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: resendLoyaltyPinConfig.headers,
      data: requestBodyFormatter({
        method: resendLoyaltyPinConfig.method,
        loyalty_card,
        ...resendLoyaltyPinConfig.body,
      }),
    };
    axios(resendPinOptions).then((res) => {
      if (res.data.response === 'OK') {
        dispatch(
          addNotification(
            `A PIN reminder has been sent to the email associated with the ${cardPhrase}.`,
            'success',
          ),
        );
      } else {
        dispatch(
          addNotification(
            `There was a problem resending the PIN. ${res.data.detail} (${res.data.code})`,
            'danger',
          ),
        );
      }
    });
  };

  const debitLoyaltyReward = (
    reward: RewardItem,
    quantity: number,
    closeModal?: () => void,
    checkBasket?: CheckBasketParams,
  ) => {
    setIsDebiting(true);
    const existingReward = redeemedLoyaltyRewards.find(
      (lrl) => lrl.rewardId === reward.rewardId,
    );
    const rewardQuantity = existingReward
      ? quantity + existingReward.quantity
      : quantity;

    const debitRewardConfig = debitReward();

    const debitRewardOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: debitRewardConfig.headers,
      data: requestBodyFormatter({
        method: debitRewardConfig.method,
        siteId: selectedVenue?.id,
        type: reward.type,
        rewardId: reward.rewardId,
        quantity: rewardQuantity,
        transactionId: existingReward?.transactionId,
        ...debitRewardConfig.body,
      }),
    };

    axios(debitRewardOptions)
      .then((response) => {
        const data = response.data as DebitLoyaltySiteRewardResponse;
        if (data.response === 'OK') {
          if (isErrorResponse(data)) {
            dispatch(
              addNotification(
                `There was an unexpected error while debiting your ${rewardPhrase}. Please try again.`,
                'danger',
              ),
            );
          } else {
            const redeemedLoyaltyReward: RedeemedRewardItem = {
              transactionId: data.transactionId,
              displayName: reward.displayName,
              rewardId: reward.rewardId,
              rewardType: reward.type,
              quantity: rewardQuantity,
            };

            closeModal && closeModal();
            checkBasket &&
              checkBasket(false, { loyaltyReward: redeemedLoyaltyReward });
          }
        } else {
          const errResponse = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `${errResponse.detail} (${errResponse.code})`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            `There was an unexpected error redeeming your ${rewardPhrase}. Please try again.`,
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsDebiting(false);
      });
  };

  const creditLoyaltyReward = (
    transactionId: string,
    rewardId: string,
    rewardType: string,
    quantity: number = 1,
    checkBasketCallback?: CheckBasketParams,
    setStateOnComplete: boolean = true,
  ) => {
    setIsCrediting(true);
    let rewardsForCheckBasket = cloneDeep(redeemedLoyaltyRewards);
    const creditRewardConfig = creditReward();
    const creditRewardOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: creditRewardConfig.headers,
      data: requestBodyFormatter({
        method: creditRewardConfig.method,
        siteId: selectedVenue?.id,
        transactionId,
        type: rewardType,
        rewardId,
        quantity,
        ...creditRewardConfig.body,
      }),
    };

    axios(creditRewardOptions)
      .then((response) => {
        const data = response.data as CreditLoyaltySiteRewardResponse;
        if (data.response === 'OK') {
          if (isErrorResponse(data)) {
            dispatch(
              addNotification(
                `There was an unexpected error while crediting your ${rewardPhrase}. Please try again.`,
                'danger',
              ),
            );
          } else {
            rewardsForCheckBasket = filterRemovedRewards(
              transactionId,
              quantity,
            );
            setStateOnComplete &&
              setRedeemedLoyaltyRewards(rewardsForCheckBasket);
          }
        } else {
          const errResponse = response.data as ErrorResponse;
          // Reward expired - remove from state and checkBasket again
          if (errResponse.code === -421) {
            rewardsForCheckBasket = filterRemovedRewards(transactionId);
            setStateOnComplete &&
              setRedeemedLoyaltyRewards(rewardsForCheckBasket);
          }
          // Reward redemption not supported - remove all rewards
          else if (errResponse.code === -422) {
            setRedeemedLoyaltyRewards([]);
            dispatch(
              addNotification(
                `${errResponse.detail} (${errResponse.code})`,
                'danger',
              ),
            );
          } else {
            dispatch(
              addNotification(
                `${errResponse.detail} (${errResponse.code})`,
                'danger',
              ),
            );
          }
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            `There was an unexpected error refunding your ${rewardPhrase}`,
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsCrediting(false);
        checkBasketCallback &&
          checkBasketCallback(false, { loyaltyRewards: rewardsForCheckBasket });
      });
  };

  const enrollLoyaltyUser = (
    isOnUserScreen?: boolean,
    checkBasketCallback?: CheckBasketParams,
  ) => {
    setIsVerifying(true);
    const enrollLoyaltyUserConfig = userEnrollLoyalty();
    const enrollLoyaltyUserOptions: AxiosRequestConfig = {
      url,
      method: 'POST',
      headers: enrollLoyaltyUserConfig.headers,
      data: requestBodyFormatter({
        method: enrollLoyaltyUserConfig.method,
        emailAddress: userEmail,
        userId,
        ...enrollLoyaltyUserConfig.body,
      }),
    };

    const errorMessage = `Unfortunately we are not able to give you ${getLoyaltyLinkTitle(
      cardPhraseArticle,
      cardPhrase,
    )} just now. ${
      canAssociateLoyaltyCard
        ? `Please try again later from the ${getLoyaltyLinkTitle(
            loyaltyProgramPhrase,
            cardPhrase,
          )} section.`
        : ''
    }`;

    axios(enrollLoyaltyUserOptions)
      .then((response) => {
        const data = response.data as UserEnrollLoyaltyResponse;
        if (data.user) {
          dispatch(updateUserData('loyalty_card', data.user.loyalty_card));
          dispatch(
            addNotification(
              `You've been awarded ${getLoyaltyLinkTitle(
                cardPhraseArticle,
                cardPhrase,
              )}`,
              'success',
            ),
          );
        } else {
          dispatch(addNotification(errorMessage, 'danger'));
        }
      })
      .catch(() => {
        dispatch(addNotification(errorMessage, 'danger'));
      })
      .finally(() => {
        setIsVerifying(false);
        if (!isOnUserScreen) {
          if (isRegisteringFromCheckout && checkBasketCallback) {
            checkBasketCallback(true);
            dispatch(registerFromCheckout(false));
          } else {
            history.push(from.pathname);
          }
        }
      });
  };

  const addLoyaltyCard = (
    formData: FieldValues,
    checkBasketCallback?: CheckBasketParams,
    isOnUserScreen?: boolean,
  ) => {
    setIsVerifying(true);
    const userAddLoyaltyNumberConfig = userAddLoyaltyNumber();
    const userAddLoyaltyNumberOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: userAddLoyaltyNumberConfig.headers,
      data: requestBodyFormatter({
        method: userAddLoyaltyNumberConfig.method,
        ...formData,
        ...userAddLoyaltyNumberConfig.body,
      }),
    };

    axios(userAddLoyaltyNumberOptions)
      .then((response) => {
        const data = response.data as UserAddLoyaltyNumberResponse;
        if (data.response.toLowerCase() === 'ok') {
          dispatch(
            receiveUser(response.headers, data.user, data['X-Auth-UserToken']),
          );
          dispatch(
            addNotification('Your card was successfully verified', 'success'),
          );

          if (!isOnUserScreen) {
            if (isRegisteringFromCheckout && checkBasketCallback) {
              checkBasketCallback(true);
              dispatch(registerFromCheckout(false));
            } else {
              history.push(from.pathname);
            }
          }
        } else {
          const err = response.data as ErrorResponse;
          handleAddLoyaltyError(err, isOnUserScreen);
        }
      })
      .catch(() => {
        displayLoyaltyError();
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const handleAddLoyaltyError = (
    error: ErrorResponse,
    isOnUserScreen?: boolean,
  ) => {
    const errorCodesToShow = [-984, -985, -986, -988];
    if (errorCodesToShow.includes(error.code)) {
      if (
        error.code === -986 &&
        error.detail.includes('Please try again in 5 minutes.')
      ) {
        if (!isOnUserScreen) {
          history.push(from.pathname);
        }
        dispatch(addNotification(error.detail, 'danger'));
      } else {
        displayLoyaltyError(error.detail, isOnUserScreen);
      }
    } else if (error.code === -9871) {
      displayLoyaltyError(
        'We were not able to verify your card as it has been suspended.',
        isOnUserScreen,
      );
    } else {
      displayLoyaltyError('', isOnUserScreen);
    }
  };

  const displayLoyaltyError = (
    errorMessage?: string,
    isOnUserScreen?: boolean,
  ) => {
    const defaultErrorMessage = `Unfortunately we are not able to link your ${cardPhrase} just now. ${
      canAssociateLoyaltyCard
        ? `Please try again later from the ${getLoyaltyLinkTitle(
            loyaltyProgramPhrase,
            cardPhrase,
          )} section.`
        : ''
    }`;
    if (isOnUserScreen) {
      dispatch(addNotification(errorMessage || defaultErrorMessage, 'danger'));
    } else {
      setAddLoyaltyError(errorMessage || defaultErrorMessage);
    }
  };

  const unlinkLoyaltyCard = (cardNumber: number) => {
    const unlinkLoyaltyCardConfig = userUnlinkLoyaltyCard();
    const unlinkLoyaltyCardOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: unlinkLoyaltyCardConfig.headers,
      data: requestBodyFormatter({
        method: unlinkLoyaltyCardConfig.method,
        cardNumber,
        ...unlinkLoyaltyCardConfig.body,
      }),
    };
    axios(unlinkLoyaltyCardOptions).then((response) => {
      const data = response.data as UnlinkLoyaltyCardResponse;
      if (data.response.toLowerCase() === 'ok') {
        dispatch(updateUserData('loyalty_card', ''));
        dispatch(
          addNotification('Your card was successfully unlinked', 'success'),
        );
      } else {
        const errResponse = response.data as ErrorResponse;
        dispatch(
          addNotification(
            `${errResponse?.detail} (${errResponse?.code})`,
            'danger',
          ),
        );
      }
    });
  };

  const removeAllRewards = (checkBasketCallback?: CheckBasketParams) => {
    redeemedLoyaltyRewards.forEach((lr) => {
      creditLoyaltyReward(
        lr.transactionId,
        lr.rewardId,
        lr.rewardType,
        lr.quantity,
        undefined,
        false,
      );
    });
    setRedeemedLoyaltyRewards([]);
    checkBasketCallback && checkBasketCallback(false, { loyaltyRewards: [] });
  };

  const filterRemovedRewards = (
    transactionId: string,
    quantity?: number,
  ): RedeemedRewardItem[] => {
    const loyaltyLineToRemove = redeemedLoyaltyRewards.find(
      (lr) => lr.transactionId === transactionId,
    );

    if (loyaltyLineToRemove) {
      const remainingQuantity = quantity
        ? loyaltyLineToRemove.quantity - quantity
        : 0;

      if (remainingQuantity > 0) {
        return redeemedLoyaltyRewards.map((line) =>
          line.transactionId === transactionId
            ? { ...line, quantity: remainingQuantity }
            : line,
        );
      } else {
        return redeemedLoyaltyRewards.filter(
          (line) => line.transactionId !== transactionId,
        );
      }
    }
    return redeemedLoyaltyRewards;
  };

  useEffect(() => {
    if (isFetchingLoyalty) {
      fetchLoyaltyRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingLoyalty]);

  return (
    <LoyaltyRewardContext.Provider
      value={{
        initialised: true,
        addLoyaltyCard,
        addLoyaltyError,
        creditLoyaltyReward,
        debitLoyaltyReward,
        enrollLoyaltyUser,
        getLoyaltyRewards,
        isCrediting,
        isDebiting,
        isLoyaltyRewardsLoading,
        isVerifying,
        loyaltyRewards,
        loyaltyRewardsError,
        redeemedLoyaltyRewards,
        setAddLoyaltyError,
        setRedeemedLoyaltyRewards,
        removeAllRewards,
        resendPin,
        unlinkLoyaltyCard,
        verifyUserLoyalty,
      }}
    >
      {children}
    </LoyaltyRewardContext.Provider>
  );
};
