import { usePhrases } from 'contexts/ConfigContext';
import { getLoyaltyLinkTitle } from 'utils';

export const UserHasNoLoyalty: React.FC = () => {
  const {
    loyalty: { cardPhraseArticle, cardPhrase },
  } = usePhrases();

  const cardPhraseToUse = getLoyaltyLinkTitle(cardPhraseArticle, cardPhrase);

  return (
    <div className="user-has-loyalty">
      <h2 className="no-margin-bottom">No {cardPhrase}</h2>
      <p>
        When {cardPhraseToUse} has been allocated to your account, it will
        appear here.
      </p>
    </div>
  );
};
