import cs from 'classnames';
import { useFormContext, Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { Field, Option } from 'types/models/Forms';
import VirtualisedSelect from 'react-select-virtualized';
import { useEffect } from 'react';

interface VirtualSelectProps {
  formField: Field;
  autocomplete?: string;
  selectedOption?: Option;
}

export const VirtualSelect: React.FC<VirtualSelectProps> = ({
  formField,
  autocomplete,
  selectedOption,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { required, label, placeholder, name, options, disabled } = formField;

  const labelToUse = label ? label : name;

  const requiredLabel = required ? <span className="required">*</span> : null;

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  const colourStyles: StylesConfig = {
    menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 }),
  };

  useEffect(() => {
    if (selectedOption) {
      setValue(formField.name, {
        value: selectedOption.value,
        label: selectedOption.label,
      });
    } else if (formField.content) {
      const foundOption = formField?.options?.find(
        (option) => option?.value === formField.content,
      );
      if (foundOption) {
        setValue(formField.name, {
          ...foundOption,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses} data-testid={`${name}-selector`}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        defaultValue={''}
        render={({ field }) => (
          <VirtualisedSelect
            {...field}
            options={options}
            placeholder={
              placeholder ? placeholder : `Select your ${labelToUse}`
            }
            aria-invalid={hasError ? 'true' : 'false'}
            aria-required={required ? 'true' : 'false'}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              spellCheck: 'off',
            }}
            isClearable={false}
            menuShouldScrollIntoView={true}
            styles={colourStyles}
            classNamePrefix={
              hasError ? 'location-info-has-error' : 'location-info'
            }
            autocomplete={autocomplete}
            isDisabled={disabled}
          />
        )}
      />
      {fieldError && (
        <span
          className="text-danger"
          role="alert"
          data-testid={`alert-block-error-${name}`}
        >
          {'userFieldType' in formField ? formField.label : labelToUse} is
          required.
        </span>
      )}
    </div>
  );
};
