import { useConfig, usePhrases } from 'contexts/ConfigContext';

import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { MdPerson } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInUser } from 'user/reducer';
import { logOut } from 'user/actions';
import { useState } from 'react';
import { useBasket } from 'contexts/BasketContext';
import { useHistory } from 'react-router';
import { selectOtpEnabled } from 'user/selectors';
import { useCheckout } from 'contexts/CheckoutContext';
import { getLoyaltyLinkTitle } from 'utils';

interface LoggedInHeaderProps {
  closeNav: () => void;
}

export const LoggedInHeader: React.FC<LoggedInHeaderProps> = ({ closeNav }) => {
  const {
    accountDeletionOnly,
    changePasswordLink,
    disableUserSignIn,
    loyalty: { userShowLoyaltyCard },
    myDetailsLink,
    supportsRewardsRedemption,
  } = useConfig();

  const {
    loyalty: { loyaltyProgramPhrase, cardPhrase },
  } = usePhrases();

  const dispatch = useDispatch();
  const history = useHistory();

  const { clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();

  const user = useSelector(selectLoggedInUser).toJS();
  const otpEnabled = useSelector(selectOtpEnabled);

  const [isOpen, setIsOpen] = useState(false);

  const redirect = (location: string) => {
    history.push(location);
  };

  const logout = () => {
    dispatch(logOut(false, clearBasket, clearCheckout));
  };

  if (accountDeletionOnly || disableUserSignIn) {
    return null;
  }

  return (
    <Nav
      bsStyle="tabs"
      className="no-top-margin"
      pullRight
      data-testid="header-logged-in-nav"
    >
      <NavDropdown
        title={
          <div className="inline-block header-link">
            <MdPerson className="react-icon" />
            {` ${user.firstName} ${user.lastName}`}
          </div>
        }
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        id="nav-dropdown"
        data-testid="header-logged-in-dropdown"
        open={isOpen}
      >
        {userShowLoyaltyCard && supportsRewardsRedemption ? (
          <MenuItem
            onSelect={() => {
              setIsOpen(false);
              redirect('/user/loyalty');
            }}
            onClick={closeNav}
            data-testid="header-loyalty-link"
          >
            {getLoyaltyLinkTitle(loyaltyProgramPhrase, cardPhrase)}
          </MenuItem>
        ) : null}
        <MenuItem
          onSelect={() => {
            setIsOpen(false);
            redirect('/user/order');
          }}
          onClick={closeNav}
          data-testid="header-order-history-link"
        >
          Order history
        </MenuItem>
        <MenuItem
          onSelect={
            myDetailsLink
              ? () => {
                  setIsOpen(false);
                  window.open(myDetailsLink, '_blank');
                }
              : () => {
                  setIsOpen(false);
                  redirect('/user/edit');
                }
          }
          onClick={closeNav}
          data-testid="header-my-details-link"
        >
          My details
        </MenuItem>
        <MenuItem
          onSelect={
            changePasswordLink
              ? () => {
                  setIsOpen(false);
                  window.open(changePasswordLink, '_blank');
                }
              : () => {
                  setIsOpen(false);
                  redirect('/user/password-change');
                }
          }
          onClick={closeNav}
          data-testid="header-change-password-link"
        >
          Change password
        </MenuItem>
        {otpEnabled ? (
          <MenuItem
            onSelect={() => {
              setIsOpen(false);
              redirect('/user/delete-account');
            }}
            onClick={closeNav}
            data-testid="header-delete-account-link"
          >
            Delete My Account
          </MenuItem>
        ) : null}
        <MenuItem divider />
        <MenuItem
          onSelect={() => {
            setIsOpen(false);
            logout();
          }}
          onClick={closeNav}
          data-testid="header-log-out-link"
        >
          Log out
        </MenuItem>
      </NavDropdown>
    </Nav>
  );
};
