import { useConfig } from 'contexts/ConfigContext';

export const LoyaltyCardImage: React.FC = () => {
  const {
    loyalty: { addLoyaltyCard },
  } = useConfig();

  return addLoyaltyCard.imageURL ? (
    <div className="loyalty-image-container">
      <img
        src={addLoyaltyCard.imageURL}
        className="loyalty-image"
        alt={addLoyaltyCard.altText}
        role="presentation"
      />
    </div>
  ) : null;
};
