import { useSignUp } from 'hooks/useSignUp';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { FormGenerator } from 'core/components/RHF';
import { Loader } from 'components/Loader';
import { Sidebar } from 'user/components/LoggedInUserComponents/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingEdit } from 'user/selectors';
import { selectUser } from 'user/reducer';
import { edit } from 'user/actions';

export const Edit: React.FC = () => {
  const dispatch = useDispatch();
  const { formData, isLoadingForms } = useSignUp();
  const user = useSelector(selectUser).toJS();
  const isFetchingEdit = useSelector(selectIsFetchingEdit);

  const methods = useForm({
    mode: 'all',
  });

  const normaliseEditForm = (form: FieldValues): FieldValues => {
    const normalisedForm: FieldValues = {};
    Object.entries(form).forEach(([key, value]) => {
      // Check for select input to format the value else use regular value
      normalisedForm[key] = value?.value ?? value;
    });
    return normalisedForm;
  };

  const handleEdit: SubmitHandler<FieldValues> = async (values) => {
    dispatch(edit(normaliseEditForm(values)));
    methods.reset(undefined, { keepValues: true });
  };

  const formToUse = formData?.editUser ?? formData?.edit;

  if (formToUse) {
    // remove any password fields - user should not be able to edit password on this page
    formToUse.splice(
      0,
      formToUse.length,
      ...formToUse.filter((field) => field.type !== 'password'),
    );
    // map user values to corresponding fields
    formToUse.forEach((field) => {
      const userField = user.user[field.name];
      if (userField) {
        field.content = userField;
      }
      if (field.name === '') {
        field.disabled = true;
      }
    });
  }

  if (isLoadingForms || !formData) {
    return (
      <div className="container">
        <div className="panel panel-default">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Sidebar />
      <div className="col-sm-9">
        <div className="panel panel-default">
          {formToUse ? (
            <>
              <h2>My Details</h2>
              <FormProvider {...methods}>
                <FormGenerator definition={formToUse} />
                <Button
                  bsStyle="primary"
                  type="submit"
                  data-testid="button-update-details"
                  disabled={isFetchingEdit || !methods.formState.isDirty}
                  onClick={methods.handleSubmit(handleEdit)}
                >
                  Update Details
                </Button>
              </FormProvider>
            </>
          ) : (
            <div>Sorry, something has gone wrong. Please try again.</div>
          )}
        </div>
      </div>
    </div>
  );
};
