import { usePhrases } from 'contexts/ConfigContext';
import { addLoyaltyOption } from 'types/models/Loyalty';
import { CheckBoxOption } from 'user/components/Loyalty/AddLoyalty/CheckboxOption';
import { getLoyaltyLinkTitle } from 'utils';

interface AddLoyaltyCheckboxesProps {
  checkedOption: addLoyaltyOption;
  onChange: (option: addLoyaltyOption) => void;
}

export const AddLoyaltyCheckboxes: React.FC<AddLoyaltyCheckboxesProps> = ({
  checkedOption,
  onChange,
}) => {
  const { loyalty: loyaltyPhrases } = usePhrases();

  return (
    <>
      <CheckBoxOption
        label={`No, I don't have ${getLoyaltyLinkTitle(
          loyaltyPhrases.cardPhraseArticle,
          loyaltyPhrases.cardPhrase,
        )}`}
        inputProps={{
          id: 'no-card-choice',
          name: 'no-card',
          value: 'noLoyalty',
          'aria-label': 'No loyalty card',
          checked: checkedOption === addLoyaltyOption.noLoyalty,
        }}
        onChange={() => {
          onChange(addLoyaltyOption.noLoyalty);
        }}
      />
      <CheckBoxOption
        label={`Yes, I have ${getLoyaltyLinkTitle(
          loyaltyPhrases.cardPhraseArticle,
          loyaltyPhrases.cardPhrase,
        )}`}
        inputProps={{
          id: 'card-choice',
          name: 'has-card',
          value: 'hasLoyalty',
          'aria-label': 'Has loyalty card',
          checked: checkedOption === addLoyaltyOption.hasLoyalty,
        }}
        onChange={() => {
          onChange(addLoyaltyOption.hasLoyalty);
        }}
      />
    </>
  );
};
