import { RootStateOrAny } from 'react-redux';
import { LoginMethod } from 'types/models/User';

export const selectIsLoggedIn = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isLoggedIn'));

export const selectIsFetchingLogIn = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isFetchingLogIn'));

export const selectIsFetchingGuestCheckout = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isFetchingGuestCheckout'));

export const selectIsFetchingEdit = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isFetchingEdit'));

export const selectIsFetchingChangePassword = (
  state: RootStateOrAny,
): boolean => Boolean(state.user.get('isFetchingChangePassword'));

export const selectIsRegisteringFromCheckout = (
  state: RootStateOrAny,
): boolean => Boolean(state.user.get('isRegisteringFromCheckout'));

// Registration Page
export const selectIsFetchingRegister = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isFetchingRegister'));

// OTP
export const selectOTPModalFlag = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('otpModalFlag'));

export const selectOTPMethod = (state: RootStateOrAny): string =>
  state.user.get('otpMethod');

export const selectOTPAccount = (state: RootStateOrAny): string =>
  state.user.get('otpAccount');

export const selectLoginMethod = (state: RootStateOrAny): LoginMethod =>
  state.user.get('loginMethod');

export const selectUserLoyaltyCardNumber = (state: RootStateOrAny): number =>
  state.user.get('user').get('loyalty_card');

export const selectUserHasLoyaltyCard = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('user').get('loyalty_card'));

export const selectIsFetchingResetPassword = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('isFetchingResetPassword'));

export const selectUserEmail = (state: RootStateOrAny): string =>
  state.user.get('user').get('email');

export const selectUserId = (state: RootStateOrAny): number =>
  state.user.get('user').get('id');

export const selectOtpEnabled = (state: RootStateOrAny): boolean =>
  Boolean(state.user.get('otpEnabled'));
