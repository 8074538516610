import { Sidebar } from 'user/components/LoggedInUserComponents';
import { UserLoyaltyBody } from 'user/components/Loyalty/UserLoyalty/UserLoyaltyBody';

export const UserLoyaltyPage: React.FC = () => {
  return (
    <div className="container">
      <Sidebar />
      <div className="col-sm-9">
        <div className="panel panel-default add-loyalty">
          <UserLoyaltyBody />
        </div>
      </div>
    </div>
  );
};
