import { useEffect } from 'react';

import cs from 'classnames';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { AdditionalRules, Field } from 'types/models/Forms';
import {
  isNotOnlyWhitespace,
  isRequired,
  maxLength,
  minLength,
} from 'core/components/RHF/validation';

interface TextProps {
  field: Field;
  autocomplete?: string;
  additionalRules?: AdditionalRules;
}

export const Text: React.FC<TextProps> = ({
  field,
  autocomplete,
  additionalRules,
}) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { min, max, required, label, placeholder, name, disabled } = field;

  const labelToUse = label ? label : name;

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const requiredLabel = required ? <span className="required">*</span> : null;

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  const registerOptions: RegisterOptions = {
    ...isRequired(
      'userFieldType' in field ? field.label : labelToUse,
      required,
    ),
    ...maxLength(labelToUse, max ? max : 9999),
    ...minLength(labelToUse, min ? min : 0),
    validate: {
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
      ...additionalRules,
    },
  };

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <input
        className="form-control"
        type="text"
        placeholder={placeholder ? placeholder : labelToUse}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={hasError ? 'true' : 'false'}
        {...register(name, registerOptions)}
        autoComplete={autocomplete}
        disabled={disabled}
      />
      {fieldError && (
        <span
          className="help-block"
          role="alert"
          data-testid={`alert-block-error-${name}`}
        >
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
