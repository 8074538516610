import { usePhrases } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { LoyaltyCardImage } from 'user/components/Loyalty/SharedComponents';
import { selectUserLoyaltyCardNumber } from 'user/selectors';
import { getLoyaltyLinkTitle } from 'utils';

export const UserHasLoyalty: React.FC = () => {
  const {
    loyalty: { loyaltyProgramPhrase, cardPhrase },
  } = usePhrases();

  const cardNumber = useSelector(selectUserLoyaltyCardNumber);
  return (
    <div className="user-has-loyalty">
      <h2 className="no-margin-bottom">
        {getLoyaltyLinkTitle(loyaltyProgramPhrase, cardPhrase)}
      </h2>
      <LoyaltyCardImage />
      <b className="text-center">
        {cardPhrase}: {cardNumber}
      </b>
    </div>
  );
};
