import { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Grid,
  Panel,
  Row,
} from 'react-bootstrap';
import { reduxForm } from 'redux-form/immutable';
import { Notification } from 'core/components/Notification';
import { NotFound } from 'routers';
import { Password } from 'core/components/RHF/FieldTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { useConfig } from 'contexts/ConfigContext';
import { resetPasswordByToken } from 'user/actions';
import { selectIsFetchingChangePassword } from 'user/selectors';
import { useHistory } from 'react-router';

const ResetPassword = ({ handleSubmit }) => {
  const { forms } = useConfig();
  const dispatch = useDispatch();
  const history = useHistory();

  const [token, setToken] = useState(null);

  const isFetchingResetPassword = useSelector(selectIsFetchingChangePassword);

  let tmpToken = window.location.hash.match(/token%253D.+/)
    ? window.location.hash.match(/token%253D.+/)[0]
    : '';

  if (tmpToken) {
    tmpToken = token.slice(10);

    setToken(tmpToken);
  }

  const handleResetPasswordByToken = (formData, token) => {
    dispatch(resetPasswordByToken(formData, token, history));
  };

  const getPasswordField = () => {
    let field = null;

    if (forms) {
      forms.register.forEach((el) => {
        el.fields.forEach((o) => {
          if (o.type === 'password') {
            field = o;
          }
        });
      });
    }

    return field;
  };

  const button = isFetchingResetPassword ? (
    <Button
      bsStyle="primary"
      type="submit"
      onClick={handleSubmit((formData) =>
        handleResetPasswordByToken(formData, token),
      )}
      disabled
    >
      Loading
    </Button>
  ) : (
    <Button
      bsStyle="primary"
      type="submit"
      onClick={handleSubmit((formData) =>
        handleResetPasswordByToken(formData, token),
      )}
    >
      Submit
    </Button>
  );

  return (
    <>
      {token && getPasswordField() ? (
        <Grid>
          <Notification />
          <Panel>
            <h2>Enter your new password</h2>
            <Form
              onSubmit={handleSubmit((formData) =>
                handleResetPasswordByToken(formData, token),
              )}
            >
              <Row>
                <Col sm={12}>
                  <Password field={getPasswordField()} />
                </Col>
              </Row>
              <FormGroup>{button}</FormGroup>
            </Form>
          </Panel>
        </Grid>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default reduxForm({ form: 'password-request' })(ResetPassword);
