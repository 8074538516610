import { useSignUp } from 'hooks/useSignUp';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { Button } from 'react-bootstrap';
import { FormGenerator } from 'core/components/RHF';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingRegister,
  selectIsLoggedIn,
  selectIsRegisteringFromCheckout,
} from 'user/selectors';
import { useEffect, useRef, useState } from 'react';
import { WLWOLocationState } from 'types/models/WLWOLocationState';
import { register as registerAction, registerFromCheckout } from 'user/actions';
import { useCheckout } from 'contexts/CheckoutContext';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { addNotification } from 'core/actions';

export const Register: React.FC = () => {
  const dispatch = useDispatch();
  const {
    captchaDisabled,
    prodCaptchaSitekey,
    loyalty: { shouldPromptForAssociationPostSignUp },
  } = useConfig();
  const { formData, isLoadingForms } = useSignUp();
  const { checkBasket, isFetchingBasket, setIsFetchingBasket } = useCheckout();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const history = useHistory();

  const methods = useForm<FieldValues>({
    mode: 'all',
  });

  const {
    login: { createAccountText },
  } = usePhrases();

  const location = useLocation<WLWOLocationState>();
  const { from } = location.state || { from: { pathname: '/venues' } };

  const [isCheckingRecaptcha, setIsCheckingRecaptcha] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isFetchingRegister = useSelector(selectIsFetchingRegister);
  const isRegisteringFromCheckout = useSelector(
    selectIsRegisteringFromCheckout,
  );

  const formToUse = formData?.register;

  const normaliseRegisterForm = (form: FieldValues): FieldValues => {
    const normalisedForm: FieldValues = {};
    Object.entries(form).forEach(([key, value]) => {
      // Check for select input to format the value else use regular value
      normalisedForm[key] = value?.value ?? value;
    });
    return normalisedForm;
  };

  const handleRegister: SubmitHandler<FieldValues> = async (values) => {
    if (captchaDisabled) {
      dispatch(
        registerAction(
          normaliseRegisterForm(values),
          history,
          isRegisteringFromCheckout ? setIsFetchingBasket : false,
          checkBasket,
          shouldPromptForAssociationPostSignUp,
          from,
        ),
      );
    } else if (recaptchaRef.current) {
      setIsCheckingRecaptcha(true);
      const token = await recaptchaRef.current.executeAsync();
      axios.post(`/recaptcha?response=${token}`).then((res) => {
        if (res.data.success) {
          dispatch(
            registerAction(
              normaliseRegisterForm(values),
              history,
              isRegisteringFromCheckout ? setIsFetchingBasket : false,
              checkBasket,
              shouldPromptForAssociationPostSignUp,
              from,
            ),
          );
        } else {
          dispatch(
            addNotification(
              'Something went wrong, please try again.',
              'danger ',
            ),
          );
        }
      });
    }
    setIsCheckingRecaptcha(false);
    recaptchaRef.current?.reset();
  };

  if (formToUse) {
    // remove loyalty card field - user should not be able to edit loyalty details on this page
    formToUse.splice(
      0,
      formToUse.length,
      ...formToUse.filter((field) => field.name !== 'loyalty_card'),
    );
  }

  useEffect(() => {
    if (from?.pathname === '/checkout') {
      dispatch(registerFromCheckout(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn && !isFetchingBasket) {
    return <Redirect to={from} />;
  }

  if (isLoadingForms || !formData) {
    return (
      <div className="container">
        <div className="panel panel-default">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="container container-md">
      <p className="text-right">
        <Link to="/user/login">Already have an account? Log in here</Link>
      </p>
      <div className="panel panel-default" data-testid="registration-form">
        {formData?.register ? (
          <>
            <h2>We Just Need A Few Details</h2>
            <FormProvider {...methods}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={
                  prodCaptchaSitekey ||
                  '6LdqtnoUAAAAAPuSlZ2wVCCtmQGYNd-PvSfaFVQw'
                }
              />
              <div className="col-sm-12">
                <FormGenerator definition={formData.register} />
              </div>
              <Button
                bsStyle="primary"
                className="btn-pe-submit"
                type="submit"
                onClick={methods.handleSubmit(handleRegister)}
                data-testid="register-create-account-button"
                disabled={isFetchingRegister || isCheckingRecaptcha}
              >
                {createAccountText}
              </Button>
            </FormProvider>
          </>
        ) : (
          <div>Sorry, something has gone wrong. Please try again.</div>
        )}
      </div>
    </div>
  );
};
