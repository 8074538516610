import { ListGroup } from 'react-bootstrap';
import { SpecialRequestField } from 'menu/components/MenuModal/ModalComponents';
import { useConfig } from 'contexts/ConfigContext';
import { AztecProduct } from 'types/models';
import { AllergenLink } from 'menu/components/Allergens';
import { PortionItem } from 'menu/components/MenuModal/ModalBody';
import { sortPortionsByPriceThenId, updatePortionQuantity } from 'menu/utils';

/**
 * Produces list of portions, which can be individually selected via a button.
 */

interface SelectPortionModalProps {
  aztecProduct: AztecProduct;
  updateAztecProduct: (product: AztecProduct) => void;
}

export const SelectPortionModal: React.FC<SelectPortionModalProps> = ({
  aztecProduct,
  updateAztecProduct,
}) => {
  const { specialRequestsDisabled } = useConfig();
  const displayRecord =
    aztecProduct.displayRecords[aztecProduct.displayRecordId];
  const portionArray = Object.values(aztecProduct.portions);

  const onPortionClick = (portionId: number) => {
    const updatedProduct = updatePortionQuantity(aztecProduct, portionId, 1);
    updateAztecProduct(updatedProduct);
  };

  // NOT SURE ON THE SCENARIOS IN WHICH THIS HAPPENS
  // const selectedItemProductId = isLineChoice(currentNode)
  //   ? currentNode.productId
  //   : currentNode.item.product.productId;

  // const displayRecordId = isLineChoice(currentNode)
  //   ? currentNode.choiceGroupDispRecIdToUse
  //   : currentNode.item.product.displayRecordId;

  const portionComponents = sortPortionsByPriceThenId(portionArray).map(
    (portion) => {
      return (
        <PortionItem
          key={`${aztecProduct.id}-${portion.id}`}
          portion={portion}
          onPortionClick={onPortionClick}
        />
      );
    },
  );

  return (
    <>
      <div className="display-record-description">
        {displayRecord?.description}
        <div>
          <AllergenLink />
        </div>
      </div>
      <ListGroup>{portionComponents}</ListGroup>
      {!specialRequestsDisabled ? (
        <SpecialRequestField aztecProduct={aztecProduct} />
      ) : null}
    </>
  );
};
