import ReactDialog from 'react-modal';
import { Button } from 'react-bootstrap';

type ButtonOptions = 'default' | 'success' | 'primary' | 'destructive';
interface DialogProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  title?: string;
  description?: string | React.ReactNode;
  accept?: {
    text: string;
    action: () => void;
    btnType?: ButtonOptions;
    destructive?: boolean;
    datatestid?: string;
  };
  reject?: { text: string; action: () => void; datatestid?: string };
  datatestid?: string;
}

export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onRequestClose,
  title,
  description,
  accept,
  reject,
  datatestid,
}) => {
  return (
    <ReactDialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 10000,
        },
        content: {
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        },
      }}
      overlayClassName="dialog-overlay"
      testId={datatestid}
    >
      {title ? <h2 className="dialog-title">{title}</h2> : null}
      {description ? (
        <div className="dialog-description">{description}</div>
      ) : null}
      {accept && (
        <Button
          className={
            accept.btnType
              ? `dialog-${accept.btnType}-button`
              : 'dialog-success-button'
          }
          onClick={accept.action}
          data-testid={accept.datatestid}
        >
          {accept.text}
        </Button>
      )}
      {reject && (
        <Button
          className="hide-modal-button"
          onClick={reject.action}
          data-testid={reject.datatestid}
        >
          {reject.text}
        </Button>
      )}
    </ReactDialog>
  );
};

ReactDialog.setAppElement('#root');
